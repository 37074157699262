var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"base-container-x contact-header-box",staticStyle:{"min-height":"500px","background-color":"#fff"}},[_c('div',{staticClass:"mt-3"},[_c('b-col',{staticClass:"p-2",attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"mt-2"},[_c('h5',{staticClass:"text-primary-dark"},[_vm._v(_vm._s(_vm.$t('Add Event')))])]),_c('b-row',[_c('b-col',{staticClass:"col col-sm-12 col-lg-6"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[(!_vm.image)?_c('div',{staticClass:"border border-1 rounded w-100  d-flex justify-content-center align-items-center add-img-gallery",staticStyle:{"height":"345px !important"}},[_c('span',{staticClass:"position-absolute text-muted",staticStyle:{"margin-top":"90px"}},[_vm._v("Widht 900px X Height 506px")]),_c('div',[_c('b-icon',{staticClass:"ml-1",staticStyle:{"color":"#DBDBDB"},attrs:{"scale":"3","title":_vm.$t('Add'),"icon":"image"}})],1)]):_c('div',{staticClass:"border border-1 rounded  d-flex justify-content-center align-items-center add-img-gallery",staticStyle:{"height":"345px"}},[_c('b-img',{staticClass:"w-100 rounded",staticStyle:{"max-height":"345px !important"},attrs:{"src":_vm.image,"fluid":""}})],1)]),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('Image'),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline col col-12 px-0 mt-2 font-weight-bold text-primary-dark",attrs:{"for":"input-image","label":_vm.$t('Image')}},[_c('b-form-file',{attrs:{"placeholder":_vm.$t('Choose a file or drop it here'),"drop-placeholder":_vm.$t('Drop file here'),"type":"file","accept":"image/*","id":"input-image"},on:{"change":_vm.onFileChange},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('p',{staticClass:"small text-muted p-1 mb-1"},[_vm._v(_vm._s(_vm.$t('Support image type and Image resolution, {width} {height}', { width: '900px', height: '506px' })))]),_c('small',{staticClass:"text-danger pxx-1"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6","sm":"12"}},[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Subject'),"autocomplete":"off","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline w-100 font-weight-bold text-primary-dark"},[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-event-subject"}},[_vm._v(_vm._s(_vm.$t('Subject'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-event-subject","type":"text","placeholder":_vm.$t('Enter subject')},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-daterange"}},[_vm._v(_vm._s(_vm.$t('Date'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('date-range-picker',{ref:"datepicker",staticClass:"w-100",attrs:{"id":"input-daterange","autoApply":true,"ranges":false},on:{"update":_vm.updateValueDaterange},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(picker.startDate,'YYYY-MM-DD'))+" "+_vm._s(_vm.$t('To'))+" "+_vm._s(_vm._f("dateFormat")(picker.endDate,'YYYY-MM-DD'))+" ")]}}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start time'),"autocomplete":"off","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-start-time"}},[_vm._v(_vm._s(_vm.$t('Start time'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('timepicker',{staticStyle:{"height":"36px"},attrs:{"id":"input-start-time","type":"minute","timeStr":_vm.timeStr,"locale":_vm.currentLanguage,"placeholder":_vm.$t('No time selected'),"btnStr":_vm.$t('Select')},model:{value:(_vm.form.start_time),callback:function ($$v) {_vm.$set(_vm.form, "start_time", $$v)},expression:"form.start_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-2",attrs:{"lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('End time'),"autocomplete":"off","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-end-time"}},[_vm._v(_vm._s(_vm.$t('End time'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('timepicker',{staticStyle:{"height":"36px"},attrs:{"id":"input-end-time","type":"minute","timeStr":_vm.timeStr,"locale":_vm.currentLanguage,"placeholder":_vm.$t('No time selected'),"btnStr":_vm.$t('Select')},model:{value:(_vm.form.end_time),callback:function ($$v) {_vm.$set(_vm.form, "end_time", $$v)},expression:"form.end_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-3",attrs:{"lg":"12","sm":"12"}},[_c('label',{staticClass:"text-primary-dark w-100 font-weight-600",attrs:{"for":"currency"}},[_vm._v(_vm._s(_vm.$t('Currency'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Currency'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"currency","options":_vm.currencies,"value-field":"code","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-3",attrs:{"lg":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Price'),"role":"required|numeric|double:2","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline w-100 font-weight-bold text-primary-dark"},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"input-price"}},[_vm._v(_vm._s(_vm.$t('Price'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-price","type":"text","placeholder":_vm.$t('Price')},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Limit Register'),"role":"numeric","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline w-100 font-weight-bold text-primary-dark",attrs:{"for":"input-limit","label":_vm.$t('Limit Register')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-limit","type":"number","placeholder":_vm.$t('Limit Register')},model:{value:(_vm.form.limited),callback:function ($$v) {_vm.$set(_vm.form, "limited", $$v)},expression:"form.limited"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-0 pr-0 col-sm-12 col-lg-12"},[_c('validation-provider',{attrs:{"name":_vm.$t('Description'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline col-12 font-weight-bold text-primary-dark"},[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-description"}},[_vm._v(_vm._s(_vm.$t('Description'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-textarea',{staticClass:"input-auth",attrs:{"id":"input-description","type":"text","rows":"5","max-rows":"10","placeholder":_vm.$t('Enter Description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-0 pr-0 col-sm-12 col-lg-12"},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline col-12 font-weight-bold text-primary-dark"},[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-address"}},[_vm._v(_vm._s(_vm.$t('Address'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"input-address","type":"text","placeholder":_vm.$t('Address')},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('Location'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"location"}},[_vm._v(_vm._s(_vm.$t('Location'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('v-select',{staticClass:"form-v-selects",attrs:{"id":"location","placeholder":_vm.$t('Province or States'),"label":"text","filterable":false,"options":_vm.regions},on:{"search":_vm.searchRegion},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search 11",on:{"click":_vm.setReadOnly}},'input',attributes,false),events))]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('No results found for'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('Typing to search for a Province or States')))])]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.text)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.text)+" ")])]}}],null,true),model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-0 col-sm-12 col-lg-6"},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline col-12 pr-2 font-weight-bold text-primary-dark"},[_c('label',{staticClass:"text-primary-dark font-weight-bold",attrs:{"for":"input-email"}},[_vm._v(_vm._s(_vm.$t('Email'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-email","type":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"px-0 col-sm-12 col-lg-6"},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone number'),"rules":"numeric","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline col-12 pl-2 font-weight-bold text-primary-dark",attrs:{"for":"input-phonenumber","label":_vm.$t('Phone number')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-phonenumber","type":"text","placeholder":_vm.$t('Phone number')},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('span',{staticClass:"text-18 font-weight-bold text-primary-dark"},[_vm._v(_vm._s(_vm.$t('Social media')))]),_c('br'),_c('validation-provider',{attrs:{"name":_vm.$t('Website'),"rules":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark",attrs:{"for":"input-website","label":_vm.$t('Website')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-website","type":"text","placeholder":_vm.$t('Website')},model:{value:(_vm.form.website_url),callback:function ($$v) {_vm.$set(_vm.form, "website_url", $$v)},expression:"form.website_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Facebook URL'),"rules":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark",attrs:{"for":"input-facebook-graduated","label":_vm.$t('Facebook URL')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-facebook-graduated","type":"text","placeholder":_vm.$t('Facebook URL')},model:{value:(_vm.form.facebook_url),callback:function ($$v) {_vm.$set(_vm.form, "facebook_url", $$v)},expression:"form.facebook_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Twitter URL'),"rules":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark",attrs:{"for":"input-twitter","label":_vm.$t('Twitter URL')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-twitter","type":"text","placeholder":_vm.$t('Twitter URL')},model:{value:(_vm.form.twitter_url),callback:function ($$v) {_vm.$set(_vm.form, "twitter_url", $$v)},expression:"form.twitter_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Instagram URL'),"rules":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark",attrs:{"for":"input-instagram","label":_vm.$t('Instagram URL')}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-instagram","type":"text","placeholder":_vm.$t('Instagram URL')},model:{value:(_vm.form.instagram_url),callback:function ($$v) {_vm.$set(_vm.form, "instagram_url", $$v)},expression:"form.instagram_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]),_c('div',{staticClass:"row"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"sm":"12","lg":"12"}},[_c('b-button',{staticClass:"mt-2  px-5 my-4 btn-light font-weight-bold d-inline",attrs:{"pill":"","type":"reset","disabled":_vm.disableBtn},on:{"click":_vm.setForm}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('b-button',{staticClass:"d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark",attrs:{"pill":"","type":"submit","disabled":_vm.isLoading || _vm.disableBtn}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(_vm._s(_vm.$t('Save')))],1)],1)],1)],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }